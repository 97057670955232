import React from 'react'
import propTypes from 'prop-types'
import { useParams } from 'react-router'
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa'
import moment from 'moment'
import classNames from 'classnames'

import Buttonless from 'components/Buttonless'
import Game, {
  STATUS_FINISHED,
  STATUS_PLAYING,
} from 'layouts/Game'
import gamesApi from 'api'
import Grid, { Letter } from 'scenes/Crossword/Board/Grid'
import Header from 'layouts/Header'
import I18n from 'lang'
import Layout from 'layouts/Layout'
import Question from './Board/Question'
import Timer from 'components/Timer'
import useGame from 'hooks/useGame'
import { ReactComponent as EmojiSmile } from 'assets/emoji-smile-fill.svg'
import { ReactComponent as ListUlIcon } from 'assets/list-ul.svg'
import { ReactComponent as BackspaceIcon } from 'assets/backspace.svg'
import { gamesApiBase, getUserId } from 'api'


export function Keyboard(props) {
  const {
    onClick,
    backspaceClick,
    locale,
  } = props

  const extraKey = locale === 'es' ? 'Ñ' : 'Ç'
  const keyboard = [
    {
      key: 'Q',
    },
    {
      key: 'W',
    },
    {
      key: 'E',
    },
    {
      key: 'R',
    },
    {
      key: 'T',
    },
    {
      key: 'Y',
    },
    {
      key: 'U',
    },
    {
      key: 'I',
    },
    {
      key: 'O',
    },
    {
      key: 'P',
    },
    {
      key: 'A',
    },
    {
      key: 'S',
    },
    {
      key: 'D',
    },
    {
      key: 'F',
    },
    {
      key: 'G',
    },
    {
      key: 'H',
    },
    {
      key: 'J',
    },
    {
      key: 'K',
    },
    {
      key: 'L',
    },
    {
      key: extraKey,
    },
    {
      key: 'Z',
    },
    {
      key: 'X',
    },
    {
      key: 'C',
    },
    {
      key: 'V',
    },
    {
      key: 'B',
    },
    {
      key: 'N',
    },
    {
      key: 'M',
    },
  ]

  return (
    <div className='sticky bottom-0 mt-10 z-30'>
      {props.children}
      <div className='bg-disabled-lighter p-2 pb-4 grid grid-cols-10 gap-x-1 gap-y-2'>
        {
          keyboard.map(
            key => (
              <Buttonless
                key={key.key}
                className='
                  uppercase text-3xl
                  flex items-center justify-center
                  h-14
                  !font-semibold
                  text-disabled bg-white border border-disabled-lighter
                  hover:bg-primary hover:text-white
                  rounded
                '
                onClick={() => {
                  onClick(key.key)
                }}
              >
                {key.key}
              </Buttonless>
            ),
          )
        }
        <Buttonless
          className='
            uppercase text-2xl
            flex items-center justify-center
            h-14
            !font-semibold
            text-disabled bg-white border border-disabled-lighter
            rounded
            col-span-3
            hover:bg-primary hover:text-white
          '
          onClick={() => {
            backspaceClick()
          }}
        >
          <BackspaceIcon />
        </Buttonless>
      </div>
    </div>
  )
}


export default function Crossword(props) {
  const {
    type,
    location,
    gameProps,
  } = props

  const {
    title,
    url: baseUrl,
    color: bgColor,
    icon: {
      big: iconBigSrc,
    },
    api: {
      basepath: baseUrlApi,
    }
  } = gameProps

  const { id='last', locale } = useParams()
  const isMobile = 'ontouchstart' in document.documentElement

  const [activeCrossword, setActiveCrossword] = React.useState({})
  const [statistics, setStatistics] = React.useState([])
  const [allWordsCompleted, setAllWordsCompleted] = React.useState(false)
  const [mode, setMode] = React.useState('grid')

  const [, updateState] = React.useState()

  const forceUpdate = React.useCallback(async (save = false) => {
    if (save && activeCrossword.id) {
      saveGame(false, '', true)
    }
    crossWordIsCompleted()
    checkQuestions()
    updateState({})
  }, [activeCrossword]) // eslint-disable-line react-hooks/exhaustive-deps

  const alreadyChecked = React.useRef(false)
  const selectedLetters = React.useRef([])
  const activeQuestion = React.useRef([])
  const itemActive = React.useRef([])
  const isHorizontal = React.useRef(true)
  const _board = React.useRef({})
  const attributeQuestions = React.useRef('horizontalQuestions')

  const verticalArrows = (code, onlyInWord=false) => {
    const grid = _board.current.grid

    let counter = 1
    const operation = code === 'ArrowDown' ? 1 : -1
    let index = counter * operation

    while (
      (
        ! onlyInWord &&
        grid[itemActive.current[0] + index] &&
        grid[itemActive.current[0] + index][itemActive.current[1]].divider
      ) || (
        onlyInWord &&
        grid[itemActive.current[0] + index] &&
        ! grid[itemActive.current[0] + index][itemActive.current[1]].divider &&
        grid[itemActive.current[0] + index][itemActive.current[1]].value !== ''
      )
    ) {
      counter += 1
      index = operation * counter
    }

    if (
      grid[itemActive.current[0] + index] &&
      ! grid[itemActive.current[0] + index][itemActive.current[1]].divider
    ) {
      if (
        ! onlyInWord ||
        (
          onlyInWord &&
          ! grid[itemActive.current[0] + index][itemActive.current[1]].divider &&
          grid[itemActive.current[0] + index][itemActive.current[1]].value === ''
        )
      ) {
        onClickItem(itemActive.current[0] + index, itemActive.current[1], true)
      }
    }
  }

  const horizontalArrows = (code, onlyInWord=false) => {
    const grid = _board.current.grid

    let counter = 1
    const operation = code === 'ArrowRight' ? 1 : -1
    let index = counter * operation

    while (
      (
        ! onlyInWord &&
        grid[itemActive.current[0]][itemActive.current[1] + index] &&
        grid[itemActive.current[0]][itemActive.current[1] + index].divider
      ) || (
        onlyInWord &&
        grid[itemActive.current[0]][itemActive.current[1] + index] &&
        ! grid[itemActive.current[0]][itemActive.current[1] + index].divider &&
        grid[itemActive.current[0]][itemActive.current[1] + index].value !== ''
      )
    ) {
      counter += 1
      index = operation * counter
    }

    if (
      grid[itemActive.current[0]][itemActive.current[1] + index] &&
      !grid[itemActive.current[0]][itemActive.current[1] + index].divider
    ) {
      if (
        ! onlyInWord ||
        (
          onlyInWord &&
          ! grid[itemActive.current[0]][itemActive.current[1] + index].divider &&
          grid[itemActive.current[0]][itemActive.current[1] + index].value === ''
        )
      ) {
        onClickItem(itemActive.current[0], itemActive.current[1] + index, true)
      }
    }
  }

  const onKeyDown = e => {
    if (
      e.code === 'ArrowDown' ||
      e.code === 'ArrowUp' ||
      e.code === 'ArrowLeft' ||
      e.code === 'ArrowRight' ||
      e.code === 'Space' ||
      e.key === 'Tab'

    )
      e.preventDefault()
  }

  const onKeyUp = e => {
    e.preventDefault()
    e.stopPropagation()
    if (itemActive.current.length > 0) {
      if (e.key === 'Escape') {
        itemActive.current = []
        activeQuestion.current = []
        deselectAllWords()
      }

      if (e.key === 'Backspace') {
        resetItem()

      } else if (e.key === 'Tab') {
        selectNextQuestion()

      } else if (e.code === 'Space') {
        onClickItem(itemActive.current[0], itemActive.current[1], true)

      } else if (
        e.code === 'ArrowDown' ||
        e.code === 'ArrowUp'
      ) {
        verticalArrows(e.code)

      } else if (
        e.code === 'ArrowLeft' ||
        e.code === 'ArrowRight'
      ) {
        horizontalArrows(e.code)

      }

      if (
        (e.keyCode >= 65 && e.keyCode <= 90) ||
        e.key === 'Ñ' ||
        e.key === 'ñ' ||
        e.key === 'Ç' ||
        e.key === 'ç'
      ) {
        changeItem(e.key)
      }
    }
  }

  const {
    initGame,
    setPause,
    setPlaying,

    // actions
    restart,
    resolve,
    validate,
    save,
    autoSave,
    setShowEndGameModal,

    // variables
    status,
    time,
    message,
    modal,
    isLoading,
    showEndGameModal,

  } = useGame({
    onKeyUp,
    onKeyDown,
    startFrom: parseInt(activeCrossword?.estadouser?.tiempo || 0, 10),
    statusInit: activeCrossword?.estadouser?.id,
    expirationDate: activeCrossword?.despublicado,
    locale,
  })

  React.useEffect(() => {
    if (
      ! alreadyChecked.current &&
      allWordsCompleted &&
      status === STATUS_PLAYING
    ) {
      validateCrossword()
    }

  }, [allWordsCompleted]) // eslint-disable-line react-hooks/exhaustive-deps

  const fetchStatistics = async () => {
    const response = await gamesApi.post(`/user/stats/${baseUrlApi}`)

    setStatistics(response.data)
  }

  const fetchCrossword = async () => {
    const response = await gamesApi.get(`/${baseUrlApi}/get/${id}`)
    const dataFetch = response.data
    let isFinished = false
    if ([2, 3].includes(dataFetch.estadouser.id)) {
      isFinished = true
    }

    let numGrid = []
    if (dataFetch.tipo === 1) {
      numGrid = dataFetch.numcuadricula.split('\r\n').map(
        row => row.split(',')
      )
    }

    const grid = dataFetch.cuadricula.split('\r\n').map(
      (row, indexRow) => row.split('').map(
        (letter, indexCol) => {
          return {
            letter,
            letterError: null,
            value: (
              isFinished ?
                letter :
                (
                  (
                    dataFetch?.estadouser?.grid &&
                    dataFetch?.estadouser?.grid[indexRow] &&
                    dataFetch?.estadouser?.grid[indexRow][indexCol]
                  ) ?
                    dataFetch?.estadouser?.grid[indexRow][indexCol] :
                    ''
                )
            ),
            divider: letter === '#',
            error: false,
            selected: false,
            num: dataFetch.tipo === 1 ? numGrid[indexRow][indexCol] : null,
          }
        }
      )
    )

    const invertMatrix = (data) => {
      return data.map(
        (row, indexRow) => row.map(
          (col, indexCol) => {
            return (indexCol in data && indexRow in data[indexCol]) ? data[indexCol][indexRow] : ''
          }
        )
      )
    }

    const processIndexQuestions = (questions) => {
      return questions.split('\r\n').reduce(
        (acc, question) => {
          const [index, q] = question.split('-')
          return {
            ...acc,
            [index]: q
          }
        }, {}
      )
    }

    // minicrossword
    const horizontalQuestionsMini = dataFetch?.preguntashorizontales_index || processIndexQuestions(dataFetch?.preguntashorizontales)
    const verticalQuestionsMini = dataFetch?.preguntasverticales_index || processIndexQuestions(dataFetch?.preguntasverticales)

    const processQuestionsMini = (data, questions) => {
      return data.map(
        (row, indexRow) => (
          row.reduce(
            (acc, col, indexCol) => {
              if (
                col.num !== null &&
                questions[col.num] !== undefined
              ) {
                const _startIndexCol = indexCol
                let _indexCol = indexCol
                while (
                  _indexCol in row &&
                  row[_indexCol].letter !== '#'
                ) {
                  _indexCol++
                }

                return [
                  ...acc,
                  {
                    start: _startIndexCol,
                    end: _indexCol - 1,
                    letters: 0,
                    question: questions[col.num] || null,
                    index: col.num,
                  },
                ]
              }

              return acc
            },
            [],
          )
        )
      )
    }

    // crossword
    const initQuestions = questions => questions.split('.').reduce(
      (red, question) => {
        if (question.replace(' ', '').length > 0) {

          return [
            ...red,
            {
              question,
              resolved: false,
            },
          ]
        }

        return red
      },
      [],
    )

    const _horizontalQuestions = dataFetch.preguntashorizontales.split(/ ?\r\n/).map(initQuestions)
    const _verticalQuestions = dataFetch.preguntasverticales.split('\r\n').map(initQuestions)

    const processQuestions = (data, questions) => {
      return data.map(
        (row, indexRow) => {
          let startIndex = 0
          let indexCol = -1

          return row.split(/#/).reduce(
            (red, word) => {
              indexCol++

              if (
                word === '#' ||
                ! word
              ) {
                indexCol--
                startIndex++
                return red
              }

              const start = startIndex
              const end = start + word.length - 1
              startIndex += word.length + 1

              const _questions = questions[indexRow][indexCol]

              if ( ! _questions) {
                return red
              }

              return [
                ...red,
                {
                  start,
                  end,
                  letters: 0,
                  ..._questions,
                },
              ]
            },
            []
          )
        }
      )
    }

    let verticalQuestions = []
    let horizontalQuestions = []

    if (dataFetch.tipo === 1) {
      verticalQuestions = processQuestionsMini(invertMatrix(grid), verticalQuestionsMini)
      horizontalQuestions = processQuestionsMini(grid, horizontalQuestionsMini)

    } else {
      const invertedDataFetch = invertMatrix(dataFetch.cuadricula.split('\r\n').map(
        row => row.split('')
      )).map(
        row => row.join('')
      )
      verticalQuestions = processQuestions(invertedDataFetch, _verticalQuestions)
      horizontalQuestions = processQuestions(dataFetch.cuadricula.split('\r\n'), _horizontalQuestions)
    }

    const board = {
      date: dataFetch.edicion,
      author: dataFetch.autor,
      published: dataFetch.publicado,
      spaces: dataFetch.espacios,
      userState: {
        state: dataFetch?.estadouser?.estado,
      },
      crosswordType: dataFetch?.tipo || 0,
      horizontalQuestions,
      verticalQuestions,
      grid,
      type,
    }

    _board.current = board
    itemActive.current = ([])

    setActiveCrossword(dataFetch)

    if (!isFinished) {
      let q
      let c = 0
      while (q === undefined) {
        if (
          horizontalQuestions?.[c]?.[0] &&
          'start' in horizontalQuestions[c][0] &&
          'end' in horizontalQuestions[c][0]
        ) {
          q = horizontalQuestions[c][0]
        } else {
          c ++
          if (c > 5) {
            q = false
          }
        }
      }

      try {
        selectQuestion(q.start, 0)
      } catch (e) {
        selectQuestion(c, 0)
      }
    }
  }

  const restartAllData = async () => {
    selectedLetters.current = []
    activeQuestion.current = []
    itemActive.current = []
    setActiveCrossword(null)
    changeIsHorizontal(true)
    _board.current = {
      date: '',
      author: '',
      published: '',
      spaces: '',
      userState: {
        state: '',
      },
      horizontalQuestions: [],
      verticalQuestions: [],
      grid: [],
    }

    fetchStatistics()
    await initGame(fetchCrossword)

  }

  const checkQuestions = () => {
    _board.current.horizontalQuestions.forEach(
      (row, indexRow) => row.forEach(
        question => {
          question.resolved = _board?.current.grid[indexRow]?.slice(
            question.start,
            question.end + 1,
          )?.every(
            item => item.value !== ''
          )
        }
      )
    )

    _board.current.verticalQuestions.forEach(
      (col, indexCol) => col.forEach(
        question => {
          question.resolved = _board?.current?.grid?.slice(
            question.start,
            question.end + 1,
          )?.every(
            row => {
              return row[indexCol]?.value !== ''
            }
          )
        }
      )
    )
  }

  const restartCrowssword = async () => {

    try {
      await restart(
        baseUrlApi,
        {
          gameid: activeCrossword.id,
        }
      )
      restartAllData()

    } catch (error) {}

  }

  const resolveCrowssword = async () => {

    try {
      await resolve(
        baseUrlApi,
        {
          gameid: activeCrossword.id,
          gamedata: {
            tiempo: time,
          },
        }
      )
      restartAllData()

    } catch (error) {}

  }

  const validateCrossword = async () => {
    if (alreadyChecked.current === true) {
      return
    }

    const validated = await validate(
      baseUrlApi,
      {
        gameid: activeCrossword.id,
        gamedata: {
          tiempo: time,
          grid: _board.current.grid.map(
            row => row.map(
              item => item.value
            )
          ),
        },
      }
    )

    if (validated) {
      await fetchCrossword()
    }
  }

  const changeIsHorizontal = value => {
    isHorizontal.current = value

    if (value) {
      attributeQuestions.current = 'horizontalQuestions'

    } else {
      attributeQuestions.current = 'verticalQuestions'

    }
  }

  const saveGame = async (exit = false, newUrl = null, auto = false) => {

    let grid = []
    _board.current.grid.forEach(
      (row, indexRow) => row.forEach(
        (item, indexCol) => {
          grid[indexRow] = grid[indexRow] || []
          grid[indexRow][indexCol] = item.value
        }
      )
    )

    const fnc = auto ? autoSave : save

    try {
      await fnc(
        baseUrlApi,
        {
          gameid: activeCrossword.id,
          gamedata: {
            tiempo: time,
            grid,
          },
        },
        exit,
        newUrl,
      )

    } catch (error) {}

  }

  const crossWordIsCompleted = () => {
    const { grid } = _board.current

    const isCompleted = grid?.every(
      row => row.every(
        item => item.divider || item.value !== ''
      )
    )

    setAllWordsCompleted(isCompleted)

    return isCompleted
  }

  const checkLetter = (indexRow = false, indexCol = false, _forceUpdate=true) => {
    if (indexRow === false && indexCol === false) {
      indexRow = itemActive.current[0]
      indexCol = itemActive.current[1]
    }

    const elActive = _board.current.grid[indexRow][indexCol]

    if (elActive.divider) {
      return
    }

    elActive.letterError = null
    if (elActive.value.toUpperCase() !== elActive.letter.toUpperCase()) {
      elActive.letterError = true

    } else {
      elActive.letterError = false

    }

    if (_forceUpdate) {
      forceUpdate()
    }

    return ! elActive.letterError
  }

  const checkWord = () => {
    selectedLetters.current.forEach(
      selectedLetter => {
        if (isHorizontal.current) {
          checkLetter(itemActive.current[0], selectedLetter, false)

        } else {
          checkLetter(selectedLetter, itemActive.current[1], false)

        }
      }
    )
    forceUpdate()
  }

  const checkCrossword = () => {
    const { grid } = _board.current

    const isComplete = grid.map(
      (row, indexRow) => row.map(
        (col, indexCol) => {
          return checkLetter(indexRow, indexCol, false)
        }
      )
    ).every(
      row => row.every(
        col => col
      )
    )

    alreadyChecked.current = true

    if (isComplete) {
      validateCrossword()

    }

    forceUpdate()
  }

  React.useEffect(() => {
    restartAllData()

  }, [id, title]) // eslint-disable-line

  const deselectAllWords = () => {
    _board.current.grid = _board.current?.grid.map(
      row => row.map(
        letter => ({
          ...letter,
          selected: false,
        })
      )
    )

    selectedLetters.current = []
  }

  const changeItem = value => {
    alreadyChecked.current = false
    setAllWordsCompleted(false)

    const cell = (
      (
        _board.current.grid &&
        _board.current.grid[itemActive.current[0]]
      ) &&
      _board.current.grid[itemActive.current[0]][itemActive.current[1]]
    )

    if (!cell) return

    const oldValue = cell.value
    cell.value = value
    cell.letterError = null

    if (value !== '') {
      isHorizontal.current ? horizontalArrows('ArrowRight', true) : verticalArrows('ArrowDown', true)
    } else {
      if (oldValue === '') {
        isHorizontal.current ? horizontalArrows('ArrowLeft') : verticalArrows('ArrowUp')
      }
    }

    forceUpdate(true)
  }

  const resetItem = () => {
    changeItem('')
  }

  const onClickItem = (
    indexRow,
    indexCol,
    forceSelection = false,
    toggleOrientation = true,
    reintent = true,
  ) => {
    if (
      ! _board.current.grid[indexRow] ||
      ! _board.current.grid[indexRow][indexCol]
    ) {
      return
    }

    if (
      toggleOrientation &&
      itemActive.current.length > 0 &&
      _board.current?.grid[indexRow][indexCol].selected && (
        (
          itemActive.current[0] === indexRow &&
          itemActive.current[1] === indexCol &&
          forceSelection
        ) || ! forceSelection
      )
    ) {
      changeIsHorizontal(! isHorizontal.current)
    }

    itemActive.current = [indexRow, indexCol]

    const findQuestion = (indexRow, indexCol) => {
      if (
        ! _board.current ||
        ! _board.current[attributeQuestions.current] ||
        ! _board.current[attributeQuestions.current][indexRow]
      ) return

      let questionFound = false

      _board.current[attributeQuestions.current][indexRow].forEach(
        (q, indexQuestion) => {
          if (
            q.start <= indexCol &&
            q.end >= indexCol
          ) {
            selectQuestion(indexRow, indexQuestion)

            if (forceSelection) {
              itemActive.current =
                isHorizontal.current ?
                [indexRow, indexCol] :
                [indexCol, indexRow]

              forceUpdate()
            }
            questionFound = true

            return
          }
        }
      )
      if (!questionFound && reintent) {
        changeIsHorizontal(!isHorizontal.current)

        if (isHorizontal.current) {
          onClickItem(indexCol, indexRow, forceSelection, false, false)

        } else {
          onClickItem(indexRow, indexCol, forceSelection, false, false)

        }
      }
    }

    if (isHorizontal.current) {
      findQuestion(indexRow, indexCol)

    } else {
      findQuestion(indexCol, indexRow)

    }
  }

  const selectQuestion = (indexRow, indexCol) => {
    if (status === STATUS_FINISHED) {
      return
    }

    deselectAllWords()

    attributeQuestions.current = isHorizontal.current ? 'horizontalQuestions' : 'verticalQuestions'
    activeQuestion.current = [indexRow, indexCol]

    selectedLetters.current = []

    const start = _board.current[attributeQuestions.current][activeQuestion.current[0]][activeQuestion.current[1]].start
    const end = _board.current[attributeQuestions.current][activeQuestion.current[0]][activeQuestion.current[1]].end

    let hasValue = false

    for (let i = start; i <= end; i++) {
      selectedLetters.current.push(i)

      if (isHorizontal.current) {
        _board.current.grid[indexRow][i].selected = true
        hasValue = hasValue || _board.current?.grid[indexRow][i].value !== ''

      } else {
        _board.current.grid[i][indexRow].selected = true
        hasValue = hasValue || _board.current?.grid[i][indexRow].value !== ''

      }
    }

    const itemActiveInside = (
      isHorizontal.current ?
      (
        itemActive.current[0] === indexRow &&
        start <= itemActive.current[1] && end >= itemActive.current[1]
      ) :
      (
        itemActive.current[1] === indexRow &&
        start <= itemActive.current[0] && end >= itemActive.current[0]
      )
    )

    if (
      ! hasValue ||
      ! itemActiveInside
    ) {
      itemActive.current = isHorizontal.current ? [indexRow, start] : [start, indexRow]
    }

    forceUpdate()
  }

  const selectNextQuestion = () => {
    let questions = _board.current[attributeQuestions.current]

    if (
      questions[activeQuestion.current[0]][activeQuestion.current[1] + 1]
    ) {
      selectQuestion(activeQuestion.current[0], activeQuestion.current[1] + 1)

    } else if (
      questions[activeQuestion.current[0] + 1]
    ) {
      selectQuestion(activeQuestion.current[0] + 1, 0)

    } else {
      selectQuestion(0, 0)

    }

  }

  const selectPrevQuestion = () => {
    if (_board.current[attributeQuestions.current][activeQuestion.current[0]][activeQuestion.current[1] - 1]) {
      selectQuestion(activeQuestion.current[0], activeQuestion.current[1] - 1)

    } else if (_board.current[attributeQuestions.current][activeQuestion.current[0] - 1]) {
      selectQuestion(
        activeQuestion.current[0] - 1,
        _board.current[attributeQuestions.current][activeQuestion.current[0] - 1].length - 1
      )

    } else {
      selectQuestion(
        _board.current[attributeQuestions.current].length - 1,
        _board.current[attributeQuestions.current][_board.current[attributeQuestions.current].length - 1].length - 1
      )

    }
  }

  if (id === 'last' && isMobile) {
    // history.push(`/es/crossword/historical`)
    // return null
  }

  const helpMenu = [
    {
      href: '',
      disabled: status !== STATUS_PLAYING,
      onClick: () => checkLetter(),
      label: <I18n t='game.crosswords.actions.checkLetter' />,
    },
    {
      href: '',
      disabled: status !== STATUS_PLAYING,
      onClick: checkWord,
      label: <I18n t='game.crosswords.actions.checkWord' />,
    },
    {
      href: '',
      disabled: status !== STATUS_PLAYING,
      onClick: checkCrossword,
      label: <I18n t='game.crosswords.actions.checkCrossword' args={{ gameName: title }} />,
    },
    {
      href: '',
      disabled: status !== STATUS_PLAYING,
      onClick: resolveCrowssword,
      label: <I18n t='game.crosswords.actions.resolveCrossword' />,
    },
    {
      href: '',
      disabled: status !== STATUS_PLAYING,
      onClick: restartCrowssword,
      label: <I18n t='game.crosswords.actions.restartCrossword' />,
    },
  ]

  const selectorQuestions = (
    activeQuestion.current.length > 0 &&
    <div
      id='question-selector'
      className='
        bg-primary-light text-disabled-dark
        px-3 py-3 sm:mb-4 mx-auto
        flex h-16 items-center space-x-3
      '
    >
      <Buttonless className='px-2' onClick={selectPrevQuestion}><FaChevronLeft /> </Buttonless>
      <div className='flex-grow flex space-x-3 leading-4'>
        <div className='flex-grow'>{
          (
            _board.current &&
            _board.current[attributeQuestions.current] &&
            _board.current[attributeQuestions.current][activeQuestion.current[0]] &&
            _board.current[attributeQuestions.current][activeQuestion.current[0]][activeQuestion.current[1]]
          ) &&
          _board.current[attributeQuestions.current][activeQuestion.current[0]][activeQuestion.current[1]]?.question
        }</div>
      </div>
      <Buttonless className='px-2' onClick={selectNextQuestion}><FaChevronRight /></Buttonless>
    </div>
  )

  const questionsMode = (
    <div className='bg-white p-6'>
      {
        _board.current[attributeQuestions.current]?.map(
          (row, indexRow) => (
            <div key={`row_question_${indexRow}`}>
              <h3 className='!font-semibold text-2xl uppercase pb-10 pt-4'>
                {indexRow + 1}{' '}
                <I18n t={`game.crosswords.${attributeQuestions.current.replace('Questions', '')}`} />
              </h3>
              {
                row.map(
                  (item, indexItem) => (
                    <div key={`question_${indexItem}`}>
                      <div className='overflow-x-auto p-px'>
                        <div className='
                          inline-flex flex-nowrap gap-px bg-disabled-light
                          border border-disabled-light
                        '>
                          {
                            [...Array(item.end - item.start + 1).keys()].map(
                              indexLetter => {
                                const letter = (
                                  isHorizontal.current ?
                                    _board.current.grid[indexRow][item.start + indexLetter] :
                                    _board.current.grid[item.start + indexLetter][indexRow]
                                )
                                return (
                                  <div className='w-10 h-10 relative'>
                                    <Letter
                                      onClick={() => {
                                        isHorizontal.current ?
                                          onClickItem(indexRow, item.start + indexLetter, true, false) :
                                          onClickItem(item.start + indexLetter, indexRow, true, false)
                                      }}
                                      letter={letter}
                                      key={`letter_${indexLetter}`}
                                      focus={
                                        isHorizontal.current ?
                                          itemActive.current[0] === indexRow &&
                                          itemActive.current[1] === item.start + indexLetter :
                                          itemActive.current[1] === indexRow &&
                                          itemActive.current[0] === item.start + indexLetter
                                      }
                                      isSelected={
                                        isHorizontal.current ?
                                          itemActive.current[0] === indexRow &&
                                          itemActive.current[1] === item.start + indexLetter :
                                          itemActive.current[1] === indexRow &&
                                          itemActive.current[0] === item.start + indexLetter
                                      }
                                    />
                                  </div>
                                )
                              }
                            )
                          }
                        </div>
                      </div>
                      <div className='text-2xl mb-8'>
                        {item.question}
                      </div>
                    </div>
                  )
                )
              }
            </div>
          )
        )
      }
    </div>
  )

  const formatDate = (date) => {
    let dateText = moment(date).locale(locale).format('dddd, DD.MM.YY')
    return dateText.charAt(0).toUpperCase() + dateText.slice(1)
  }

  return (
    <Layout isLoading={isLoading}>
      <Header />

      <Game
        statsUrl={`/user/stats/${baseUrlApi}`}
        endGameModal={{
          isOpen: showEndGameModal,
          title,
          icon: iconBigSrc,
          titleBgColor: bgColor,
          onDate: formatDate(activeCrossword?.publicado),
          content: <div className='flex items-center space-x-5 justify-center text-yellow'>
            <EmojiSmile className='w-[75px] h-[75px] rounded-full'/>
            <div className='text-3xl uppercase'>
              <I18n t='game.messages.complete.title' />
            </div>
          </div>,
          statistics: [
            {
              i18nKey: 'game.messages.complete.completedTime',
              value: moment((activeCrossword?.estadouser?.tiempo || 0) * 1000).format('mm:ss'),
            },
            {
              i18nKey: 'game.messages.complete.averageTime',
              value: moment((activeCrossword?.promediogeneral || 0) * 1000).format('mm:ss'),
            },
          ],
          shareUrl: `${gamesApiBase}user/stats${baseUrlApi}/share?userid=${getUserId()}&id=${activeCrossword?.id}`,
          onClose: () => setShowEndGameModal(false),
        }}
        status={status}
        statistics={statistics}
        historicalGames={{
          active: !isLoading,
          url: `/${baseUrlApi}/getlist`,
          activeId: activeCrossword?.id,
          to: baseUrl,
          icon: iconBigSrc,
        }}
        publicationDate={moment(activeCrossword?.publicado).locale(locale).format('dddd, DD.MM.YY')}
        title={title}
        by={
          activeCrossword?.autor ?
          activeCrossword?.autor + ' // ' :
          ''
        }
        saveGame={() => saveGame()}
        onClickOutside={(newUrl) => saveGame(true, newUrl)}
        exitGame={status !== STATUS_FINISHED ? e => { saveGame(true); e.preventDefault() } : false}
        helpMenu={helpMenu}
        leftMenu={
          [
            {
              href: '',
              label: <I18n t='game.actions.help' />,
              options: helpMenu,
            },
          ]
        }
        mobileMenu={[
          {
            id: 'questions',
            alwaysVisible: true,
            active: mode === 'questions',
            icon: ListUlIcon,
            disabled: status !== STATUS_PLAYING,
            onClick: () => {
              setMode(mode === 'grid' ? 'questions' : 'grid')
            },
            content: (
              <div className='bg-primary text-center'>
                <div className='uppercase text-white !font-semibold text-xl py-4'>
                  <I18n t='game.crosswords.questions'/>
                </div>
                <div className='space-x-4 pb-4 uppercase'>
                  <Buttonless
                    onClick={() => {
                      onClickItem(itemActive.current[0], itemActive.current[1], true)
                    }}
                    className={classNames(
                      {
                        'bg-primary hover:bg-primary-light hover:text-primary': isHorizontal.current === false,
                        'bg-white text-primary': isHorizontal.current === true,
                      },
                      'uppercase',
                      '!font-semibold',
                      'text-white border-white',
                      'border rounded',
                      'px-4 py-2',
                    )}
                  >
                    <I18n t='game.crosswords.horizontals'/>
                  </Buttonless>
                  <Buttonless
                    onClick={() => {
                      onClickItem(itemActive.current[0], itemActive.current[1], true)
                    }}
                    className={classNames(
                      {
                        'bg-primary hover:bg-primary-light hover:text-primary': isHorizontal.current === true,
                        'bg-white text-primary': isHorizontal.current === false,
                      },
                      'uppercase',
                      '!font-semibold',
                      'text-white border-white',
                      'border rounded',
                      'px-4 py-2',
                    )}
                  >
                    <I18n t='game.crosswords.verticals'/>
                  </Buttonless>
                </div>
              </div>
            ),
          },
        ]}
        middleMenu={
          <Timer
            setPause={setPause}
            setPlaying={setPlaying}
            status={status}
            time={time}
          />
        }
      >
        {message}

        <div className='relative flex flex-col lg:flex-row gap-9 sm:p-6 justify-items-start'>
          <div
            className={classNames(
              'flex-grow-0',
              type,
            )}
          >
            <div className='sm:sticky sm:top-0'>
              {
                ! isMobile &&
                selectorQuestions
              }
              {
                mode === 'grid' && (
                  _board &&
                  <Grid
                    board={_board.current}
                    onClickItem={onClickItem}
                    itemActive={itemActive.current}
                  />
                )
              }
              { mode === 'questions' && questionsMode }
              {
                (
                  isMobile &&
                  status === STATUS_PLAYING
                ) &&
                <Keyboard
                  locale={locale}
                  onClick={changeItem}
                  backspaceClick={resetItem}
                >
                  {selectorQuestions}
                </Keyboard>
              }
            </div>
          </div>
          {
            ! isMobile &&
            <div className={classNames(
              'flex-grow grid grid-cols-2 gap-6 relative',
              { 'minicrossword': _board.current.crosswordType === 1 },
            )}>
              <Question
                gameType={_board.current.crosswordType === 1 ? 'minicrossword' : 'crossword'}
                onClickItem={
                  (indexRow, indexCol) => {
                    changeIsHorizontal(true)
                    selectQuestion(indexRow, indexCol)
                  }
                }
                type='horizontal'
                title={I18n.getTranslation(location, 'game.crosswords.horizontals')}
                questions={_board.current?.horizontalQuestions}
                activeQuestion={isHorizontal.current ? activeQuestion.current : []}
              />
              <Question
                gameType={_board.current.crosswordType === 1 ? 'minicrossword' : 'crossword'}
                onClickItem={
                  (indexRow, indexCol) => {
                    changeIsHorizontal(false)
                    selectQuestion(indexRow, indexCol)
                  }
                }
                type='vertical'
                title={I18n.getTranslation(location, 'game.crosswords.verticals')}
                questions={_board.current?.verticalQuestions}
                activeQuestion={!isHorizontal.current ? activeQuestion.current : []}
              />
            </div>
          }
        </div>

        {modal}

      </Game>

    </Layout>
  )
}


Crossword.propTypes = {
  title: propTypes.string,
  type: propTypes.string,
}

Crossword.defaultProps = {
  type: 'crossword',
}
