import React from 'react'
import { useMedia } from 'react-use';
import classNames from 'classnames'
import moment from 'moment'
import { v4 as uuidv4 } from 'uuid'
import {
  FacebookShareButton,
  TwitterShareButton,
  TelegramShareButton,
  EmailShareButton,
} from 'react-share'
import { useParams } from 'react-router-dom'
import { FaFacebookF, FaRegEnvelope, FaTelegramPlane, FaTwitter, FaWhatsapp } from 'react-icons/fa'
import { useLocation } from 'react-router-dom'
import propTypes from 'prop-types'

import I18n from 'lang'
import Title from 'layouts/Title'
import Buttonless from 'components/Buttonless'
import Menu from 'components/Menu'
import HistoricalGames from 'layouts/HistoricalGames'
import ClickOutside from 'components/ClickOutside'
import { useClickOutside } from 'hooks/useGame'
import useSiteStore from 'store/site'
import useGameStore from 'store'
import gamesApi, { getUserId } from 'api'

import { ReactComponent as Trophy21 } from 'assets/trophy-fill-21.svg'
import { ReactComponent as Trophy } from 'assets/trophy-fill.svg'
import { ReactComponent as Gear } from 'assets/gear-fill.svg'
import { ReactComponent as X } from 'assets/x-lg.svg'
import { ReactComponent as SaveFill } from 'assets/save-fill.svg'
import { ReactComponent as ArrowBarRight } from 'assets/arrow-bar-right.svg'
import { ReactComponent as Star } from 'assets/star.svg'

export const STATUS_INIT = 'init'
export const STATUS_PAUSE = 'pause'
export const STATUS_PLAYING = 'playing'
export const STATUS_FINISHED = 'finished'
export const STATUS_INCOMPLETE = 'incomplete'
export const STATUS_RESTARTED = 'restarted'
export const STATUS_RESOLVED = 'resolved'
export const STATUS_RESTART = 'restart'
export const STATUS_RESOLVE = 'resolve'
export const STATUS_PAST = 5
export const STATUS_EXIT = 'exit'
export const STATUS_TO_SAVE_AND_EXIT = 'toSaveAndExit'
export const STATUS_SAVE_AND_EXIT = 'saveAndExit'
export const STATUS_ERROR_SAVING = 'errorSaving'
export const STATUS_ERROR_RESTARTING = 'errorRestarting'
export const STATUS_ERROR_RESOLVING = 'errorResolving'
export const STATUS_ERROR_FINISHING = 'errorFinishing'
export const STATUS_ERROR_CHECKING = 'errorChecking'
export const STATUS_TO_RESOLVE = 'toResolve'


export const Overflow = props => {
  if ( ! props.active) {
    return null
  }

  return (
    <div
      className={classNames(
        props.className,
        'w-full h-full left-0 top-0 z-40',
        'bg-white bg-opacity-95',
        {
          'fixed': props.fixed,
          'absolute': ! props.fixed,
        }
      )}
    >
      <div className={classNames(
        'top-10 flex flex-col items-center space-y-10 sm:py-20',
        {
          'sticky': props.sticky,
        }
      )}>
        {props.children}
      </div>
    </div>
  )
}


export const Alert = props => {
  return (
    <div className={classNames(
      props.className,
      'bg-gray-100 shadow-md sm:rounded-xl',
      'px-10 sm:px-20 py-16 sm:py-20',
      'flex flex-col space-y-5 sm:max-w-lg',
      'relative w-full h-full sm:h-auto',
    )}>
      {
        props.onClose &&
        <Buttonless
          onClick={props.onClose}
          className='flex items-center space-x-3 absolute right-3 top-3'
        >
          <X width={14}/>
          <span>
            <I18n t='close' />
          </span>
        </Buttonless>
      }
      {props.children}
    </div>
  )
}


const ModalEndGameStatistics = ({
  titleBgColor = '',
  shareUrl: _shareUrl = '',
  endGame = undefined,

  isOpen = false,
  onClose = () => {},
  shareEndContent = false,
  statistics = [],
  share = undefined,

  icon,
  title,
  content,
  onDate,
}) => {
  const siteStore = useSiteStore()

  let shareUrl = _shareUrl
  if (!! endGame && shareUrl) {
    shareUrl = shareUrl.split('/share').join('/shareend')
  }

  if (shareUrl) {
    const t = shareUrl.includes('?') ? '&' : '?'
    shareUrl += `${t}shareuuid=${uuidv4()}&siteid=${siteStore.site?.id}`
  }

  return (
    <Overflow active={isOpen} className='z-[100]'>
      <Alert onClose={onClose} className='h-full sm:h-auto rounded-none w-full max-w-lg sticky top-0'>
        <div className='bg-disabled rounded-3xl text-white w-full'>
          {
            shareEndContent ||
            <>
              <div className='p-4 flex justify-end'>
                <img src={siteStore.site?.logo} alt={siteStore.site?.name} className='h-4' />
              </div>
              <div
                className='!font-semibold flex items-center justify-between px-3 py-2 text-3xl mb-5'
                style={{ backgroundColor: titleBgColor }}
              >
                <div className='max-h-[40px] max-w-[40px] p-1 rounded-full bg-white'>
                  {
                    icon &&
                    <img src={icon} alt='share icon' className='w-full h-full' />
                  }
                </div>
                {title}
              </div>
              <div className='p-5'>
                {content}
              </div>
              <div className='p-5 mb-4'>
                {
                  statistics?.map(
                    (statistic, index) => (
                      <div
                        key={`statistic-${index}`}
                        className='py-2 flex border-t last:border-b border-disabled-light justify-between items-center'
                      >
                        {
                          statistic.i18nKey &&
                          <I18n t={statistic.i18nKey} />
                        }
                        {statistic?.label}
                        <div className='text-xl'>{statistic.value}</div>
                      </div>
                    )
                  )
                }
                {
                  endGame &&
                  <div className='text-disabled-light py-2 flex border-t border-disabled-light justify-center items-center'>
                    <I18n t='game.messages.complete.onDate' args={onDate} />
                  </div>
                }
              </div>
            </>
          }
        </div>
        {
          share &&
          <>
            <div className='text-center text-xl !font-semibold'>
              <I18n t='game.messages.complete.share' />
            </div>
            <div className='flex justify-center items-center space-x-4'>
              <FacebookShareButton
                className='rounded-full overflow-hidden'
                url={shareUrl}
              >
                <FaFacebookF
                  size={45}
                  className='p-3 bg-disabled transition-all hover:bg-disabled-dark text-disabled-lightest'
                />
              </FacebookShareButton>
              <TwitterShareButton
                className='rounded-full overflow-hidden'
                url={shareUrl}
              >
                <FaTwitter
                  size={45}
                  className='p-3 bg-disabled transition-all hover:bg-disabled-dark text-disabled-lightest'
                />
              </TwitterShareButton>
              <a
                href={`whatsapp://send/?text=${encodeURIComponent(shareUrl)}`}
                className='rounded-full overflow-hidden'
                url={shareUrl}
              >
                <FaWhatsapp
                  size={45}
                  className='p-3 bg-disabled transition-all hover:bg-disabled-dark text-disabled-lightest'
                />
              </a>
              <TelegramShareButton
                className='rounded-full overflow-hidden'
                url={shareUrl}
              >
                <FaTelegramPlane
                  size={45}
                  className='p-3 bg-disabled transition-all hover:bg-disabled-dark text-disabled-lightest'
                />
              </TelegramShareButton>
              <EmailShareButton
                className='rounded-full overflow-hidden'
                url={shareUrl}
              >
                <FaRegEnvelope
                  size={45}
                  className='p-3 bg-disabled transition-all hover:bg-disabled-dark text-disabled-lightest'
                  body={shareUrl}
                />
              </EmailShareButton>
            </div>
          </>
        }
        {
          (endGame && ! shareEndContent) &&
          <div className='text-center text-lg'>
            <I18n t='game.messages.complete.shortDescription' />
          </div>
        }
      </Alert>
    </Overflow>
  )
}


/**
 * @param {Object} props
 * @param {string} props.status Current status of the game
 * @param {function} props.onClickOutside Function to call when clicking outside the game
 * @param {function} props.saveGame Function to save the game
 * @param {string} props.title
 * @param {HistoricalGames} props.historicalGames
 * @param {array} props.statistics Array of statistics from API
 * @param {string} props.publicationDate Date of the game from API
 * @param {array} props.mobileMenu Array of menu items. Use when it is needed to show an extra menu on mobile
 * @param {array} props.helpMenu Array of menu items
 * @param {array} props.leftMenu Array of menu items
 * @param {array} props.middleMenu Array of menu items
 * @param {string} props.by Author
 *
 * @returns {React.Component}
 */
export default function Game(props) {
  const {locale = 'es'} = useParams()
  const [showMenu, setShowMenu] = React.useState(false)
  const isMobile = useMedia('(max-width: 625px)')
  const [shareStats, setShareStats] = React.useState(null)
  const [shareStatsEnd, setShareStatsEnd] = React.useState(null)

  const location = useLocation()
  const shareuserid = new URLSearchParams(location.search).get('shareuserid')
  const shareend = new URLSearchParams(location.search).get('shareend')

  const { toasts, setToast } = useGameStore((store) => ({toasts: store.toasts, setToast: store.setToast}))
  const { autosave } = useSiteStore((store) => ({autosave: store.autosave}))

  const {
    status,
    saveGame,
    onClickOutside,
    exitGame,
    title,
    historicalGames,
    statistics,
    publicationDate,
    mobileMenu = [],
    helpMenu = [],
    leftMenu = [],
    middleMenu = [],
    by = false,
    endGameModal = {},
    rightMenuStart = [],
    rightMenuEnd = [],
    statsUrl = '',
  } = props

  React.useEffect(() => {
    const fetchStatsForUser = async () => {
      if (shareend) {
        setShareStatsEnd(shareend)
      } else {
        const response = await gamesApi.post(statsUrl, {userid: shareuserid})
        setShareStats(response.data)
      }
    }
    if (shareuserid) {
      fetchStatsForUser()
    }
  }, [shareuserid, shareend]) // eslint-disable-line react-hooks/exhaustive-deps

  const refGame = React.useRef(null)
  useClickOutside(
    refGame,
    newUrl => onClickOutside(newUrl),
    (
      status !== STATUS_INCOMPLETE &&
      status !== STATUS_FINISHED &&
      status !== STATUS_INIT
    )
  )

  const statusRef = React.useRef(null)
  const saveGameRef = React.useRef(null)
  const autosaveRef = React.useRef(null)

  React.useEffect(() => {
    statusRef.current = status
    saveGameRef.current = saveGame
    autosaveRef.current = autosave
  }, [status, saveGame, autosave])

  // React.useEffect(() => {
  //   const el = refGame.current

  //   if (!autosaveRef.current) {
  //     saveGameRef.current = () => {}
  //     el.removeEventListener('mouseout', () => {}, false)
  //     return null
  //   }

  //   el.addEventListener('mouseout', () => {
  //     saveGameRef.current && saveGameRef.current()
  //     return null
  //   })

  //   return () => {
  //     el.removeEventListener('mouseout', () => {}, false)
  //   }
  // }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const userId = getUserId()

  const rightMenu = [
    ...rightMenuStart,
    {
      href: '',
      disabled: !userId,
      onClick: () => setShowMenu(
        showMenu === 'stats' ?
          false :
          'stats'
      ),
      icon: Trophy,
      label: <I18n t='game.buttons.statistics' />,
    },
    {
      href: '/',
      disabled: status === STATUS_FINISHED,
      onClick: (e) => {
        saveGame()
        setToast({
          id: uuidv4(),
          message: I18n.getTranslation({pathname: '/' + locale}, 'game.messages.saved.message'),
        })
        e.preventDefault()
      },
      className: 'ignore-click-outside',
      icon: SaveFill,
      label: <I18n t='game.actions.save' />,
    },
    {
      onClick: exitGame,
      icon: ArrowBarRight,
      label: <I18n t='game.actions.exit' />,
    },
    ...rightMenuEnd,
  ]

  const now = moment()

  return (
    <>
      {
        toasts?.['bottom'] &&
        <div
          {...props}
          className='
            fixed bottom-4 left-0 right-0 z-[100] justify-center items-center
            pointer-events-none
            flex flex-col space-y-2
          '
        >
          {
            toasts['bottom'].map((toast, index) => (
              <div key={`toast_${toast.id}`} className={classNames(
                'bg-yellow-secondary text-black rounded-lg px-10 py-5',
                toast.className || '',
              )}>
                {toast.message}
              </div>
            ))
          }
        </div>
      }
      {
        toasts?.['top'] &&
        <div
          {...props}
          className='
            fixed top-4 left-0 right-0 z-[100] justify-center items-center
            pointer-events-none
            flex flex-col space-y-2
          '
        >
          {
            toasts['top'].map((toast, index) => (
              <div key={`toast_${toast.id}`} className={classNames(
                'bg-yellow-secondary text-black rounded-lg px-10 py-5',
                toast.className || '',
              )}>
                {toast.message}
              </div>
            ))
          }
        </div>
      }
      <div
        className='relative z-40'
        style={{minHeight: '400px'}}
      >
        <div>
          <Title
            title={title}
            by={by}
            date={publicationDate}
          />

          <HistoricalGames {...historicalGames} />

          <div className='relative pointer-events-auto' ref={refGame}>
            {
              ! isMobile &&
              <nav className='flex items-center border-t border-b border-disabled-lighter'>
                <Menu
                  parentClassName='flex-grow flex-1'
                  itemClassName='uppercase !font-semibold'
                  items={ leftMenu }
                />

                <div className='flex-grow flex-1 justify-center flex'>
                  {middleMenu}
                </div>


                <Menu
                  parentClassName='flex-grow flex-1 flex justify-end'
                  itemClassName='text-sm'
                  items={rightMenu}
                />

              </nav>
            }
            {
              (
                isMobile &&
                middleMenu
              ) &&
              <nav className='flex justify-between items-center border-t border-b border-disabled-lighter'>

                {middleMenu}

                <Menu
                  className='mr-4'
                  noPadding
                  items={[
                    ...(
                      mobileMenu.map(
                        item => (
                          {
                            ...item,
                            active: showMenu === item.id || item.active,
                            onClick: () => {
                              setShowMenu(
                                showMenu === item.id ?
                                  false :
                                  item.id
                              )
                              item.onOpen && item.onOpen()
                              item.onClick && item.onClick()
                            },
                          }
                        )
                      )
                    ),
                    {
                      icon: Trophy21,
                      onClick: (
                        () => setShowMenu(
                          showMenu === 'stats' ?
                            false :
                            'stats'
                        )
                      ),
                      active: showMenu === 'stats',
                    },
                    {
                      icon: Gear,
                      active: showMenu === 'options',
                      optionPosition: 'right',
                      options: [
                        ...helpMenu,
                        ...rightMenu,
                      ],
                    }
                  ]}
                />
              </nav>
            }
            {
              isMobile && (
                mobileMenu.length > 0 &&
                mobileMenu.map(
                  menu => {
                    if (
                      menu.id !== showMenu &&
                      ! menu.active
                    ) {
                      return null
                    }

                    return (
                      <ClickOutside
                        onClickOutside={
                          () => {
                            if (!menu.alwaysVisible) {
                              showMenu === menu.id && setShowMenu(false)
                              menu.onClose()
                            }
                          }
                        }
                      >
                        {menu.content}
                      </ClickOutside>
                    )
                  }
                )
              )
            }

            <ModalEndGameStatistics
              {...endGameModal}
              endGame
              share
            />
            <ModalEndGameStatistics
              shareEndContent={shareStatsEnd ? <img src={shareStatsEnd} alt='Share End Game' /> : null}
              isOpen={shareStatsEnd !== null}
              onClose={() => setShareStatsEnd(null)}
              endGame
            />

            <ModalEndGameStatistics
              {...endGameModal}
              isOpen={showMenu === 'stats'}
              onClose={() => setShowMenu(false)}
              statistics={
                statistics[0]?.reduce(
                  (red, statistic, index) => {
                    const value = (
                      <React.Fragment key={`statistics_${index}`}>
                        <span>
                          {
                            statistic?.type === 'date' ?
                              moment(statistic?.value * 1000).format('mm:ss') :
                              statistic.value
                          }
                        </span>{' '}
                        {
                          statistic?.percentage > 0 &&
                          <span>
                            ({parseInt(statistic.percentage * 100) / 100} %)
                          </span>
                        }
                      </React.Fragment>
                    )

                    if (statistic.valueLabel) {
                      return red
                    }
                    return [
                      ...red,
                      {
                        label: statistic.label,
                        value,
                      },
                    ]
                  },
                  []
                ) || []
              }
              content={
                statistics[0]?.map(
                  (statistic) => {
                    let value = null
                    if (statistic.valueLabel) {
                      value = (
                        <div className='text-center mt-1 w-full'>
                          <div className='uppercase'>
                            <I18n t='game.buttons.statistics' />
                          </div>
                          <div className='capitalize text-sm mb-3'>
                            {now.format('dddd DD.MM.YYYY - HH:mm')}
                          </div>
                          <div className='flex justify-around space-x-2 mb-4'>
                            {
                              [...Array(5).keys()].map(
                                i => {
                                  if (i < statistic.value) {
                                    return <Star width={50} height={50} key={`star_${i}`} style={{ color: '#F0EB4F' }} />
                                  }
                                  return <Star width={50} height={50} key={`star_${i}`} />
                                }
                              )
                            }
                          </div>
                          <div style={{ color: '#F0EB4F' }} className='uppercase mt-1'>
                            {statistic.label}: {statistic.valueLabel}
                          </div>
                        </div>
                      )
                    }
                    return value
                  }
                ) || null
              }
              titleBgColor={endGameModal?.titleBgColor}
              icon={endGameModal.icon}
              title={endGameModal.title}
              share
            />

            <ModalEndGameStatistics
              {...endGameModal}
              isOpen={shareStats !== null}
              onClose={() => setShareStats(null)}
              statistics={
                shareStats?.[0]?.reduce(
                  (red, statistic) => {
                    const value = (
                      <>
                        <span>
                          {
                            statistic?.type === 'date' ?
                              moment(statistic?.value * 1000).format('mm:ss') :
                              statistic.value
                          }
                        </span>{' '}
                        {
                          statistic?.percentage > 0 &&
                          <span>
                            ({parseInt(statistic.percentage * 100) / 100} %)
                          </span>
                        }
                      </>
                    )

                    if (statistic.valueLabel) {
                      return red
                    }
                    return [
                      ...red,
                      {
                        label: statistic.label,
                        value,
                      },
                    ]
                  },
                  []
                ) || []
              }
              content={
                shareStats?.[0]?.map(
                  (statistic) => {
                    let value = null
                    if (statistic.valueLabel) {
                      value = (
                        <div className='text-center mt-1 w-full'>
                          <div className='uppercase'>
                            <I18n t='game.buttons.statistics' />
                          </div>
                          <div className='capitalize text-sm mb-3'>
                            {now.format('dddd DD.MM.YYYY - HH:mm')}
                          </div>
                          <div className='flex justify-around space-x-2 mb-4'>
                            {
                              [...Array(5).keys()].map(
                                i => {
                                  if (i < statistic.value) {
                                    return <Star width={50} height={50} key={`star_${i}`} style={{ color: '#F0EB4F' }} />
                                  }
                                  return <Star width={50} height={50} key={`star_${i}`} />
                                }
                              )
                            }
                          </div>
                          <div style={{ color: '#F0EB4F' }} className='uppercase mt-1'>
                            {statistic.label}: {statistic.valueLabel}
                          </div>
                        </div>
                      )
                    }
                    return value
                  }
                ) || null
              }
              titleBgColor={endGameModal.titleBgColor}
              icon={endGameModal.icon}
              title={endGameModal.title}
            />

            {props.children}
          </div>
        </div>
      </div>
    </>
  )
}


Game.propTypes = {
  status: propTypes.string,
  saveGame: propTypes.func,
  title: propTypes.string,
  historicalGames: propTypes.object,
  statistics: propTypes.array,
  publicationDate: propTypes.string,
  mobileMenu: propTypes.array,
  helpMenu: propTypes.array,
  leftMenu: propTypes.array,
  middleMenu: propTypes.object,
  by: propTypes.string,
  endGameModal: propTypes.object,
  rightMenuStart: propTypes.array,
  rightMenuEnd: propTypes.array,
  statsUrl: propTypes.string,
  children: propTypes.node,
}
