import classNames from 'classnames'
import propTypes from 'prop-types'

import './Question.scss'


export default function Question(props) {
  const { title, type, gameType, questions, activeQuestion, onClickItem } = props

  if (! questions?.length) {
    return null
  }

  return (
    <div className={classNames(
      type,
      'questions',
    )}>
      <h2 className='ml-7 !font-semibold text-disabled-dark text-2xl mb-5'>{title}</h2>
      <ol className='flex flex-col'>
        {
          gameType !== 'minicrossword' ?
            questions.map(
              (line, indexRow) => (
                <li
                  style={{
                    order: line[0]?.index,
                  }} data-question={line[0]?.index} key={`question_${indexRow}_${title}`}
                >
                  {
                    line.map(
                      (question, indexCol) => (
                        <a
                          key={`question_${indexRow}_${indexCol}_${title}`}
                          onClick={e => {
                            e.preventDefault()
                            onClickItem(
                              indexRow,
                              indexCol,
                              question.start,
                              question.end,
                            )
                          }}
                          className={classNames(
                            'hover:text-primary transition-all',
                            { 'line-through': question.resolved },
                            {
                              'text-primary': (
                                indexRow === activeQuestion[0] &&
                                indexCol === activeQuestion[1]
                              ),
                              'text-disabled-dark': (
                                indexRow !== activeQuestion[0] ||
                                indexCol !== activeQuestion[1]
                              )
                            },
                          )}
                          href={question.question}
                        >
                          {question.question}{!question.question.includes('.') && '.'}
                        </a>
                      )
                    )}
              </li>
              )
            ) :
            questions.map(
              (line, indexRow) => (
                line.map(
                  (question, indexCol) => (
                    <li
                      style={{
                        order: question?.index,
                      }} data-question={question?.index} key={`question_${indexRow}_${title}`}
                    >
                      <a
                        key={`question_${indexRow}_${indexCol}_${title}`}
                        onClick={e => {
                          e.preventDefault()
                          onClickItem(
                            indexRow,
                            indexCol,
                            question.start,
                            question.end,
                          )
                        }}
                        className={classNames(
                          'hover:text-primary transition-all',
                          { 'line-through': question.resolved },
                          {
                            'text-primary': (
                              indexRow === activeQuestion[0] &&
                              indexCol === activeQuestion[1]
                            ),
                            'text-disabled-dark': (
                              indexRow !== activeQuestion[0] ||
                              indexCol !== activeQuestion[1]
                            )
                          },
                        )}
                        href={question.question}
                      >
                        {question.question}{!question.question.includes('.') && '.'}
                      </a>
                    </li>
                  )
                )
              )
            )
        }
      </ol>
    </div>
  )
}

Question.propTypes = {
  title: propTypes.string,
  type: propTypes.string,
  questions: propTypes.array,
  activeQuestion: propTypes.array,
  onClickItem: propTypes.func,
}
