import React from 'react'
import propTypes from 'prop-types'

import I18n from 'lang'
import { Alert } from 'layouts/Game'
import Button from 'components/Button'
import Buttonless from 'components/Buttonless'
import Message from 'components/Message'


export function AlertButton(props) {
  const {
    messageKey,
    onClickPrimary,
    messageI18n = `game.messages.${messageKey}.message`,
    messageArgs = {},
    buttonPrimaryI18n = `game.messages.${messageKey}.button`,
  } = props
  return (
    <div className='absolute z-[110] w-full h-full top-0 left-0 bg-white bg-opacity-90'>
      <Alert className='mx-auto sticky top-0 sm:top-24 sm:mt-24'>
          <p className='text-disabled-dark text-2xl text-center'>
          {
            messageKey ? <I18n t={messageI18n} args={messageArgs} /> : props.children
          }
        </p>
        <div className='flex space-x-5 justify-center'>
          <Button
            inverted
            className='uppercase text-center !px-11 !py-2'
            onClick={onClickPrimary}
          >
            <I18n t={buttonPrimaryI18n} />
          </Button>
        </div>
      </Alert>
    </div>
  )
}

AlertButton.propTypes = {
  active: propTypes.bool.isRequired,
  messageKey: propTypes.string.isRequired,
  onClickPrimary: propTypes.func.isRequired,
  messageI18n: propTypes.string,
  messageArgs: propTypes.object,
  buttonPrimaryI18n: propTypes.string,
}


export function Alert2Buttons(props) {
  const {
    messageKey,
    onClickPrimary,
    onClickSecondary,
    onClose,
    messageI18n = `game.messages.${messageKey}.message`,
    messageArgs = {},
    buttonPrimaryI18n = `game.messages.${messageKey}.buttonPrimary`,
    buttonSecondaryI18n = `game.messages.${messageKey}.buttonCancel`,
    buttonPrimaryProps = {},
    buttonSecondaryProps = {},
  } = props
  return (
    <div className='absolute z-[110] w-full h-full top-0 left-0 bg-white bg-opacity-90'>
      <Alert onClose={onClose} className='mx-auto sticky top-0 sm:top-24 sm:mt-24'>
        <p className='text-disabled-dark text-xl text-center'>
          <I18n t={messageI18n} args={messageArgs} />
        </p>
        <div className='flex space-x-5 justify-center'>
          <Button
            className='uppercase flex-grow'
            onClick={onClickPrimary}
            {...buttonPrimaryProps}
          >
            <I18n t={buttonPrimaryI18n} />
          </Button>
          <Button
            className='uppercase flex-grow'
            onClick={onClickSecondary}
            {...buttonSecondaryProps}
          >
            <I18n t={buttonSecondaryI18n} />
          </Button>
        </div>
      </Alert>
    </div>
  )
}

Alert2Buttons.propTypes = {
  active: propTypes.bool.isRequired,
  messageKey: propTypes.string.isRequired,
  onClickPrimary: propTypes.func.isRequired,
  onClickSecondary: propTypes.func.isRequired,
  onClose: propTypes.func,
  messageI18n: propTypes.string,
  messageArgs: propTypes.object,
  buttonPrimaryI18n: propTypes.string,
  buttonSecondaryI18n: propTypes.string,
  buttonPrimaryProps: propTypes.object,
  buttonSecondaryProps: propTypes.object,
}


export function MessagePill(props) {
  const {
    active,
    messageKey,
    messageI18n = `game.messages.${messageKey}.message`,
    messageArgs = {},
    buttonPrimaryI18n = `game.messages.${messageKey}.button`,
    buttonPrimaryProps = {},
    onClickPrimary = () => {},
    hasButtonPrimary,
    onClose = () => {},
    shareButton = null,
  } = props

  return (
    <Message active={active} onClose={onClose}>
      <p className='space-x-5 flex items-center justify-center'>
        <span>
          <I18n t={messageI18n} args={messageArgs} />
        </span>
        {
          shareButton &&
          <>
            <div className='w-px bg-black h-8' />
            <Buttonless
              className='
                inline-flex space-x-2 items-center text-base
                bg-white rounded-full px-3 py-2
                shadow-md
                hover:shadow-lg
                transition-all
              '
              onClick={shareButton}
            >
              <I18n t='game.buttons.share' />
            </Buttonless>
          </>
        }
      </p>
      {
        hasButtonPrimary &&
        <Buttonless
          className='hover:text-disabled-lighter'
          onClick={onClickPrimary}
          {...buttonPrimaryProps}
        >
          <I18n t={buttonPrimaryI18n} />
        </Buttonless>
      }
    </Message>
  )
}

MessagePill.propTypes = {
  active: propTypes.bool.isRequired,
  messageKey: propTypes.string.isRequired,
  messageI18n: propTypes.string,
  messageArgs: propTypes.object,
  hasButtonPrimary: propTypes.bool,
  buttonPrimaryI18n: propTypes.string,
  buttonPrimaryProps: propTypes.object,
  onClickPrimary: propTypes.func,
  onClose: propTypes.func,
  shareButton: propTypes.node,
}
