import classNames from 'classnames'
import propTypes from 'prop-types'

import './index.css'


export default function Button(props) {
  const Component = props.as || 'button'
  const classNameDefault = classNames(
    { 'text-black bg-white border-black border-solid': !props.active },
    { 'border-black text-white bg-disabled': props.active && !props.disabled },
    { 'hover:border-disabled-lighter': !props.disabled },
    { 'bg-disabled-lightest border-disabled-lightest text-disabled-lightest cursor-default': props.disabled },
  )
  const classNameInverted = classNames(
    { 'text-white bg-disabled': !props.active },
    { 'text-white bg-disabled-light': props.active && !props.disabled },
    { 'hover:bg-primary': !props.disabled },
    { 'bg-disabled-lightest border-disabled-lightest text-disabled-lightest cursor-default': props.disabled },
  )
  return (
    <Component
      id={props.id}
      to={props.to}
      {...props.buttonProps}
      className={classNames(
        props.className,
        'button inline-block !h-auto border-0',
        props.inverted ? classNameInverted : classNameDefault,
        'border',
        'rounded-lg',
        'px-6 py-2',
        'transition-all',
      )}
      disabled={props.disabled}
      onClick={props.onClick}
    >
      {props.children}
    </Component>
  )
}


Button.propTypes = {
  active: propTypes.bool,
  disabled: propTypes.bool,
  onClick: propTypes.func,
  buttonProps: propTypes.object,
}

