import React from 'react'
import propTypes from 'prop-types'
import { useParams } from 'react-router'
import moment from 'moment'
import { HiOutlineBackspace } from 'react-icons/hi'

import Header from 'layouts/Header'
import Layout from 'layouts/Layout'
import Game, {
  Alert,
  Overflow,
  STATUS_FINISHED,
  STATUS_PLAYING,
} from 'layouts/Game'
import Timer from 'components/Timer'
import gamesApi, { gamesApiBase, getUserId } from 'api'
import useGame from 'hooks/useGame'

import Grid from 'scenes/Words/Grid'
import useGameStore from 'store'
import { getStatuses } from './lib/statuses'
import Buttonless from 'components/Buttonless'
import classNames from 'classnames'
import Cell from './Cell'
import I18n from 'lang'


const letters = ' qwertyuiop  asdfghjklñ +zxcvbnm-'.toUpperCase().split('').map(key => ({ key }))

function Keyboard(props) {
  const {
    onClick,
    lettersStatus = {},
    className = '',
  } = props

  return (
    <div className='sticky bottom-0 mt-10 z-30'>
      {props.children}
      <div className={classNames(
        'bg-white -mx-6 md:mx-0 p-2 pb-4 grid grid-cols-10 md:grid-cols-12 gap-x-1 gap-y-2',
        className,
      )}>
        {
          letters.map(
            key => {
              if (
                key.key === '+' ||
                key.key === '-'
              ) {
                return (
                  <Buttonless
                    key={key.key === '+' ? 'Enter' : 'Backspace'}
                    className={classNames(
                      'uppercase md:text-xl',
                      {
                        'md:col-span-2': key.key === '-',
                        'col-span-2 md:col-span-3': key.key === '+',
                      },
                      'flex items-center justify-center',
                      'h-14',
                      '!font-semibold',
                      'text-disabled bg-disabled-lightest !fill-transparent',
                      'hover:bg-primary hover:text-white',
                      'rounded',
                      {
                        '!bg-green text-white': lettersStatus[key.key] === 'exact',
                        '!bg-yellow': lettersStatus[key.key] === 'included',
                        '!bg-green-opaque': lettersStatus[key.key] === 'wrong',
                      }
                    )}
                    onClick={() => {
                      onClick(key.key === '+' ? 'Enter' : 'Backspace')
                    }}
                  >
                    {
                      key.key === '-' ? (
                        <HiOutlineBackspace />
                      ) : (
                        'Enviar'
                      )
                    }
                  </Buttonless>
                )
              }
              return (
                key.key !== ' ' ? (
                  <Buttonless
                    key={key.key}
                    className={classNames(
                      'uppercase md:text-3xl',
                      'flex items-center justify-center',
                      'h-14',
                      '!font-semibold',
                      'text-disabled bg-disabled-lightest !fill-transparent',
                      'hover:bg-primary hover:text-white',
                      'rounded',
                      {
                        '!bg-green text-white': lettersStatus[key.key] === 'exact',
                        '!bg-yellow': lettersStatus[key.key] === 'included',
                        '!bg-green-opaque': lettersStatus[key.key] === 'wrong',
                      }
                    )}
                    onClick={() => {
                      onClick(key.key)
                    }}
                  >
                    {key.key}
                  </Buttonless>
                ) : (
                  <div key={key.key} className='h-14 hidden md:block' />
                )
              )
            },
          )
        }
      </div>
    </div>
  )
}


export default function Words(props) {
  const { gameProps } = props
  const { setToast } = useGameStore()

  const {
    title,
    color: bgColor,
    icon: {
      big: bigIconSrc,
    },
    api: {
      basepath: baseUrlApi,
    },
    url: baseUrl,
  } = gameProps

  const {
    id = 'last',
    locale,
  } = useParams()

  const [words, setWords] = React.useState([])
  const [howToPlay, setHowToPlay] = React.useState(false)
  const [, _forceUpdate] = React.useState({})
  const [endGameModalContent, _setEndGameModalContent] = React.useState(null)
  const [statistics, setStatistics] = React.useState([])

  const forceUpdate = React.useCallback(
    async () => {
      if (save && words.id) {
        saveGame(false, '', true)
      }
      if (attempts.current.length === 6) {
        await finishGame()
      }
      _forceUpdate({})
    },
    [words.id] // eslint-disable-line react-hooks/exhaustive-deps
  )

  const [word, setWord] = React.useState('')
  const lettersStatus = React.useRef(letters.reduce(
    (acc, letter) => ({
      ...acc,
      [letter.key]: '',
    }),
    {},
  ))
  const [notFound, setNotFound] = React.useState(false)
  const currentWord = React.useRef('')
  const attempts = React.useRef([])
  const setCurrentWord = React.useCallback((word) => {
    currentWord.current = word
    forceUpdate()
  }, [forceUpdate])

  const setEndGameModalContent = (attempts, word) => {
    let title = '¡PUEDES MEJORAR!'
    if (attempts.includes(word)) {
      switch (attempts.length) {
        case 1:
          title = 'INSPUREABLE!'
          break
        case 2:
          title = '¡FANTÁSTICO!'
          break
        case 3:
        case 4:
          title = '¡EXCELENTE!'
          break
        case 5:
          title = '¡BRAVO!'
          break
        case 6:
          title = '¡BRAVO!'
          break
        default:
          break
      }
    }

    _setEndGameModalContent(
      <div className='flex flex-col justify-center space-y-5 text-yellow'>
        <div className='text-3xl uppercase text-center'>
          {/* <I18n t='game.messages.complete.title' /> */}
          {title}
        </div>
        <div className='flex flex-col space-y-2 scale-50 !-m-[15%] md:!-m-[25%]'>
          {
            Array(6).fill()?.map((_, wIndex) => {
              const attempt = attempts[wIndex] || null
              const statuses = getStatuses(attempt, word)
              return (
                <div key={`words_row_${wIndex}`} className='flex space-x-2 justify-center'>
                  {
                    Array(word.length).fill()?.map((_, lIndex) => (
                      <Cell
                        className={classNames(
                          {
                            '!border-none': !!attempt,
                          }
                        )}
                        key={`end_game_words_cell_${wIndex}_${lIndex}`}
                        delayIndex={lIndex}
                        status={statuses[lIndex]}
                        letter={''}
                      />
                    ))
                  }
                </div>
              )
            })
          }
        </div>
      </div>
    )
  }

  const {
    initGame,
    setPause,
    setPlaying,
    setFinished,

    // actions
    finish,
    save,
    autoSave,

    // variables
    status,
    time,
    message,
    modal,
    isLoading,

    showEndGameModal,
    setShowEndGameModal,
  } = useGame({
    startFrom: parseInt(words?.estadouser?.tiempo || 0, 10),
    statusInit: words?.estadouser?.id,
    expirationDate: words?.despublicado,
    locale: locale,
    onKeyUp: async (e) => {
      if (e.key === 'Escape') {
        setPause()
      }

      if (e.key === 'Enter') {
        setPlaying()
      }

      if (
        e.key === 'Enter' ||
        (
          e?.keyCode && e.keyCode >= 65 && e.keyCode <= 90
        ) ||
        (
          e.key === 'Backspace' &&
          currentWord.current.length > 0
        )
      ) {
        await changeItem(e.key)
      }
    }
  })

  const changeItem = async (key) => {
    if (status !== STATUS_PLAYING) {
      return
    }

    if (word.length === currentWord.current.length) {
      return
    }

    if (key === 'Backspace' && currentWord.current.length === 0) {
      return
    }
    if (key === 'Backspace' && currentWord.current.length > 0) {
      currentWord.current = currentWord.current.slice(0, -1)
      forceUpdate()
      return
    }

    if (key === 'Enter' && currentWord.current.length < word.length) {
      setToast({
        title: 'Fila Incompleta',
        message: 'Fila Incompleta',
        className: '!bg-gray-700 text-white',
        position: 'top',
      })
      return
    }

    currentWord.current += key.toUpperCase()
    forceUpdate()

    if (currentWord.current === word) {
      attempts.current.push(currentWord.current)
      currentWord.current = ''
      await finishGame()
      setEndGameModalContent(attempts.current, word)
      forceUpdate()
      return
    }

    if (
      currentWord.current !== word &&
      currentWord.current.length === word.length
    ) {
      if (await checkWord(currentWord.current)) {
        attempts.current.push(currentWord.current)
        currentWord.current = ''
        setLettersStatus(attempts.current, word)
        forceUpdate()
      } else {
        setToast({
          title: '¡No encontrada!',
          message: '¡No encontrada!',
          className: '!bg-danger text-white',
          position: 'top',
        })
        setNotFound(true)
        setTimeout(() => {
          currentWord.current = ''
          setNotFound(false)
        }, 800)
        forceUpdate()
      }
      return
    }
  }

  const fetchStatistics = async () => {
    const url = `/user/stats/${baseUrlApi}`
    const response = await gamesApi.post(url)

    setStatistics(response.data)
  }

  const fetchGame = async () => {

    const response = await gamesApi.get(
      `${baseUrlApi}/get/${id}`,
      {
        params: {
        }
      },
    )
    const dataFetch = response.data
    attempts.current = dataFetch?.estadouser?.palabras || []

    setEndGameModalContent(
      attempts.current,
      dataFetch.palabra.toUpperCase(),
    )

    setWord(dataFetch.palabra.toUpperCase())

    setLettersStatus(attempts.current, dataFetch.palabra.toUpperCase())

    setWords(dataFetch)

    if (dataFetch?.estadouser?.id === 2) {
      await setFinished()
    }

  }

  const checkWord = async (word) => {
    try {
      const response = await gamesApi.get(
        `${baseUrlApi}/checkword`,
        {
          params: {
            word: word,
          },
        }
      )
      return response.data === 'true'
    } catch (error) {
      return false
    }
  }

  const finishGame = async () => {

    try {
      await finish(
        baseUrlApi,
        {
          gameid: words?.id,
          gamedata: {
            tiempo: time,
            palabras: attempts.current,
          }
        }
      )

      await fetchGame()

    } catch (error) { }

  }

  const saveGame = async (exit = false, newUrl = null, auto = false) => {

    const fnc = auto ? autoSave : save

    try {
      await fnc(
        baseUrlApi,
        {
          gameid: words?.id,
          gamedata: {
            palabras: attempts.current,
            tiempo: time,
          },
        },
        exit,
        newUrl,
      )

    } catch (error) { }

  }

  const restartAllData = () => {
    setCurrentWord('')
    fetchStatistics()
    initGame(fetchGame)
  }

  React.useEffect(() => {
    restartAllData()
  }, [baseUrlApi, id]) // eslint-disable-line

  const setLettersStatus = (attempts, word) => {
    const _lettersStatus = {}
    attempts.forEach((attempt) => {
      const status = getStatuses(attempt, word)

      attempt.split('').forEach((letter, index) => {
        const newStatus = status[index]
        const oldStatus = _lettersStatus[letter] || 'none'
        if (oldStatus === 'none') {
          _lettersStatus[letter] = newStatus
          return
        }

        if (oldStatus === 'exact') return
        if (oldStatus === 'included' && newStatus !== 'exact') return
        if (oldStatus === 'wrong' && !['included', 'exact'].includes(newStatus)) return

        _lettersStatus[letter] = newStatus
      })
    })
    lettersStatus.current = _lettersStatus
  }

  const formatDate = (date) => {
    let dateText = moment(date).locale(locale).format('dddd, DD.MM.YY')
    return dateText.charAt(0).toUpperCase() + dateText.slice(1)
  }

  const helpMenu = [
    {
      label: I18n.getTranslation({pathname: '/' + locale}, 'game.words.howToPlay'),
      href: '',
      onClick: () => setHowToPlay(true),
    },
  ]

  return (
    <Layout isLoading={isLoading}>
      <Header />
      <div className='!bg-danger' />
      <Game
        statsUrl={`/user/stats${baseUrlApi}`}
        endGameModal={{
          isOpen: showEndGameModal,
          title: title,
          icon: bigIconSrc,
          titleBgColor: bgColor,
          content: endGameModalContent,
          onDate: formatDate(words?.publicado),
          statistics: [
            {
              i18nKey: 'game.messages.complete.completedTime',
              value: moment((words?.estadouser?.tiempo || 0) * 1000).format('mm:ss'),
            },
            {
              i18nKey: 'game.messages.complete.averageTime',
              value: moment((words?.promediogeneral || 0) * 1000).format('mm:ss')
            },
          ],
          shareUrl: `${gamesApiBase}user/stats${baseUrlApi}/share?userid=${getUserId()}&id=${words?.id}`,
          onClose: () => setShowEndGameModal(false),
        }}
        status={status}
        title={title}
        statistics={statistics}
        publicationDate={moment(words?.publicado).locale(locale).format('dddd, DD.MM.YY')}
        expirationDate={moment(words?.publicado).locale(locale).format('dddd, DD.MM.YY')}
        historicalGames={{
          active: !isLoading,
          url: `${baseUrlApi}/getlist`,
          activeId: words?.id,
          to: baseUrl,
          icon: bigIconSrc,
        }}
        saveGame={() => saveGame()}
        onClickOutside={(newUrl) => saveGame(true, newUrl)}
        exitGame={status !== STATUS_FINISHED ? e => { saveGame(true); e.preventDefault() } : false}
        helpMenu={[]}
        leftMenu={[
          {
            href: '',
            label: <I18n t='game.actions.help' />,
            options: helpMenu,
          },
        ]}
        middleMenu={
          <div className='flex items-center'>

            <Timer
              setPause={setPause}
              status={status}
              time={time}
            />
          </div>
        }
      >
        <Overflow active={howToPlay}>
          <Alert onClose={() => setHowToPlay(false)}>
            <div className='text-center !font-semibold mb-5'>
              <I18n t='game.words.howToPlay' />
            </div>
            <div className='text-center'>
              <I18n t='game.words.howToPlayContent' />
            </div>
            <div className='text-center uppercase'>
              <I18n t='game.words.examples.title' />
            </div>

            <div className='text-center uppercase flex space-x-2 justify-center'>
              {
                I18n.getTranslation({pathname: '/' + locale}, 'game.words.examples.first.word')?.split('')?.map((l, lIndex) => (
                  <Cell
                    className='bg-white'
                    key={`end_game_words_cell_${lIndex}`}
                    delayIndex={lIndex}
                    status={lIndex === 0 ? 'exact' : 'none'}
                    letter={l}
                  />
                ))
              }
            </div>
            <div className='text-center'>
              <I18n t='game.words.examples.first.explanation' />
            </div>
            <div className='text-center uppercase flex space-x-2 justify-center'>
              {
                I18n.getTranslation({pathname: '/' + locale}, 'game.words.examples.second.word')?.split('')?.map((l, lIndex) => (
                  <Cell
                    className='bg-white'
                    key={`end_game_words_cell_${lIndex}`}
                    delayIndex={lIndex}
                    status={lIndex === 2 ? 'included' : 'none'}
                    letter={l}
                  />
                ))
              }
            </div>
            <div className='text-center'>
              <I18n t='game.words.examples.second.explanation' />
            </div>
            <div className='text-center uppercase flex space-x-2 justify-center'>
              {
                I18n.getTranslation({pathname: '/' + locale}, 'game.words.examples.third.word')?.split('')?.map((l, lIndex) => (
                  <Cell
                    className='bg-white'
                    key={`end_game_words_cell_${lIndex}`}
                    delayIndex={lIndex}
                    status={lIndex === 4 ? 'wrong' : 'none'}
                    letter={l}
                  />
                ))
              }
            </div>
            <div className='text-center'>
              <I18n t='game.words.examples.third.explanation' />
            </div>

            <div className='text-center !font-semibold'>
              <I18n t='game.words.footer' />
            </div>
            <div className='text-center text-sm'>
              <I18n t='game.words.disclaimer' />
            </div>
          </Alert>
        </Overflow>

        {message}
        <div className='text-center p-6 pt-3 mt-6 justify-items-start max-w-xl m-auto pb-52'>
          <Grid
            attempts={attempts.current}
            currentWord={currentWord.current}
            notFound={notFound}
            solution={word}
          />
          {
            status === STATUS_PLAYING &&
            <Keyboard
              className='justify-content-center'
              onClick={changeItem}
              locale={locale}
              lettersStatus={lettersStatus.current}
            />
          }
        </div>

        {modal}

      </Game>
    </Layout>
  )
}


Words.propTypes = {
  gameProps: propTypes.object,
  type: propTypes.string,
}

Words.defaultProps = {
  type: 'words',
}
