import React from 'react'
import classNames from 'classnames'

import I18n from 'lang'
import { ReactComponent as VaJuegoIconAmarilloIcon } from 'assets/emoji-sunglasses-fill.svg'
import { ReactComponent as VaJuegoIconGrisIcon } from 'assets/emoji-sunglasses-nofil.svg'

import { STATUS_FINISHED, STATUS_INCOMPLETED, TRANSLATE_STATUS } from 'hooks/useGame'


export const LexiRetoFaces = ({caras, showLabel=false}) => {
  const init = caras % 2 === 0 ? caras : caras - 1
  const end = caras % 2 === 0 ? caras + 1 : caras
  const i18n = `faces_${init}_${end}`

  const faces = (
    <div className={classNames(
      'flex justify-between',
      {
        'intermitentes': caras > 6,
      }
    )}>
      <div>
        {
          caras >= 1 ?
            <VaJuegoIconAmarilloIcon className='w-full'/> :
            <VaJuegoIconGrisIcon className='w-full'/>
        }
      </div>
      <div>
        {
          caras >= 2 ?
            <VaJuegoIconAmarilloIcon className='w-full'/> :
            <VaJuegoIconGrisIcon className='w-full'/>
        }
      </div>
      <div>
        {
          caras >= 3 ?
            <VaJuegoIconAmarilloIcon className='w-full'/> :
            <VaJuegoIconGrisIcon className='w-full'/>
        }
      </div>
      <div>
        {
          caras >= 4 ?
            <VaJuegoIconAmarilloIcon className='w-full'/> :
            <VaJuegoIconGrisIcon className='w-full'/>
        }
      </div>
      <div>
        {
          caras >= 5 ?
            <VaJuegoIconAmarilloIcon className='w-full'/> :
            <VaJuegoIconGrisIcon className='w-full'/>
        }
      </div>
      <div>
        {
          caras >= 6 ?
            <VaJuegoIconAmarilloIcon className='w-full'/> :
            <VaJuegoIconGrisIcon className='w-full'/>
        }
      </div>
    </div>
  )

  if (showLabel) {
    return <div className='flex flex-col space-y-4 justify-center'>
      <div className='text-3xl uppercase text-center mb-4 text-lexireto'>
        <I18n t={'game.LexiRetoGame.statusEndGame.' + i18n} />
      </div>
      {faces}
    </div>
  }
  return faces
}


export default function LexiretoWord(props) {
  const ul_list = (arr, wordsUser = []) => {
    let ul_array = [...arr].map((item, index, {length}) => {

      let letrastart = item.substr(0,1)

      let paraulauser = ''

      if (props._currentGameData.palabrasuser) {
        let palabrasstart = props._currentGameData.palabrasuser
        let exists = palabrasstart[letrastart].includes(item)

        if (!exists) {
          paraulauser = 'paraulanouser'
        }
      }

      let paraula = props._currentGameData.palabrasbenescrites[item]

      const wordWriteByUser = !! wordsUser.find(i => i.replace('-', '') === item.replace('-', ''))

      if (props._currentGameData.heptacrack.includes(item)) {
        paraulauser += ' paraulaheptacrack font-bold text-[#39A351] uppercase'
        paraula += '(¡H!)'
      } else {
        if (! wordWriteByUser) {
          paraulauser += ' italic text-[#bf910f]'
        }
      }

      return (
        <span
          key={`item-${paraula}`}
          className={classNames(
            paraulauser,
          )}
        >{paraula}{length - 1 !== index && ','} </span>
      )
    })
    return <div>{ul_array}</div>
  }


  const words = props._currentGameData.palabrasselectstart
  const wordstotales = props._currentGameData.palabrasstart
  const caras = props._currentGameData.caretas

  return (
    <div className='flex flex-col flex-grow-0 relative mx-auto px-4 max-w-sm w-full'>

      <div className='uppercase font-bold titoltuspalabrascont mb-5' id=''><I18n t='game.LexiRetoGame.comovatujuego'/></div>
      <div className='mb-6 w-full'>
        <LexiRetoFaces caras={caras} />
      </div>

      <div className='uppercase font-bold titoltuspalabrascont mb-5'>
        <I18n t='game.LexiRetoGame.marcador'/>
      </div>

      <div className='flex space-x-3 mb-5'>
        <div className='flex-grow'>
          <div className='text-sm text-center'>
            <I18n t='game.LexiRetoGame.tuspalabras'/>
          </div>
          <div className='bg-yellow-secondary text-black !font-semibold text-xl text-center py-1'>
            {props._currentGameData.contaparaules}{' '}
            <span className='text-white'>
              /  {Object.keys(props._currentGameData.palabras).length}
            </span>
          </div>
        </div>
        <div className='flex-grow'>
          <div className='text-sm text-center'>
            <I18n t='game.LexiRetoGame.heptacrackencontradas'/>
          </div>
          <div className='bg-yellow-secondary text-black !font-semibold text-xl text-center py-1'>
            {props._currentGameData.nheptacrack}{' '}
            <span className='text-white'>
              / {props._currentGameData.heptacrack.length}
            </span>
          </div>
        </div>
        <div className='flex-grow'>
          <div className='text-sm text-center'>
            <I18n t='game.LexiRetoGame.puntuacion'/>
          </div>
          <div className='bg-yellow-secondary text-black !font-semibold text-xl text-center py-1'>
            {props._currentGameData.puntos}{' '}
            <span className='text-white'>
              / {props._currentGameData.totalpuntos}
            </span>
          </div>
        </div>
      </div>

      {
        Object.keys(words).map((key, row) => (
          <div key={`word-${key}`} className='py-3 border-t-2 border-dashed border-disabled-lighter'>
            <div className='grid grid-cols-5 py-0 '>
              <div className='font-bold titoltuspalabrascont_empiezan col-span-2'>
                <I18n t='game.LexiRetoGame.empiezanpor'/><span className='uppercase'>{key}</span>
              </div>
              <div className='titoltuspalabrascont_empiezan_contador col-span-3 text-right'>
                  {words[key].length} / {wordstotales[key].length}
              </div>
            </div>
            <div className='paraules'>
              {
                (
                  TRANSLATE_STATUS[props._currentGameData.status] === STATUS_FINISHED ||
                  TRANSLATE_STATUS[props._currentGameData.status] === STATUS_INCOMPLETED
                ) ?
                  ul_list(wordstotales[key], words[key]) :
                  ul_list(words[key], words[key])
              }
            </div>
          </div>
        ))
      }
    </div>
  )
}
