import React from 'react'
import ReactDOM from 'react-dom'
import classNames from 'classnames'
import { NavLink } from 'react-router-i18n'
import { useClickAway } from 'react-use';

import Buttonless from 'components/Buttonless'
import { ReactComponent as CaretDown } from 'assets/caret-down-fill.svg'


function SubMenu(props) {
  const { options, position='left', className, opened, el } = props
  if (!opened) return null
  let offset = false
  if (el) {
    offset = el.getBoundingClientRect()
  }

  let _position = 0
  const rootGames = document.querySelector('#rootGames')
  const gameOffset = rootGames.getBoundingClientRect()

  if (position === 'left') {
    _position = offset ? offset[position] - el.ownerDocument.defaultView.pageXOffset - gameOffset.left - rootGames.ownerDocument.defaultView.pageXOffset : 0
  } else if (position === 'right') {
    _position = offset ? el.ownerDocument.defaultView.innerWidth - offset[position] - rootGames.ownerDocument.defaultView.pageXOffset : 0
  }

  return ReactDOM.createPortal(
    <ul
      className={classNames(
        className,
        'sub-menu absolute z-[120] w-60 !m-0',
        '!bg-disabled-lightest shadow-2xl',
      )}
      style={
        offset ? {
          top: offset.top + el.ownerDocument.defaultView.pageYOffset + offset.height - gameOffset.top - rootGames.ownerDocument.defaultView.pageYOffset,
          [position]: _position,
        } : {}
      }
    >
      {
        options.map(
          (option, index) => (
            <MenuItem
              noPadding
              container={{
                className: '!border-b-disabled-lighter !border-b !border-none last:!border-b-0',
              }}
              theme={false}
              className='py-3 px-4 hover:bg-primary hover:text-white w-full justify-start uppercase'
              key={`submenu_${option.href}_${index}`}
              navItem={option}
            />
          )
        )
      }
    </ul>,
    document.querySelector('#rootGames'),
  )
}


export default function MenuItem(props) {
  const [optionsOpened, setOptionsOpened] = React.useState(false)
  const ref = React.useRef(null)

  useClickAway(ref, () => {
    setTimeout(() => setOptionsOpened(false), 600)
  })

  const {
    navItem,
    className,
    noPadding,
    vertical,
    theme=true,
  } = props

  let Component = Buttonless
  let options = {}

  if (navItem.options) {
    options = {
      ref,
    }
    Component = 'span'

  } else if (navItem.href) {
    if (navItem.href.indexOf('http') === -1) {
      options = {
        ignoreLocale: true,
      }
      Component = NavLink

    } else {
      Component = 'a'

    }
  }

  if (navItem.divider) {
    return <li className='ml-2 pr-2 my-4 border-l border-disabled-lighter' />
  }

  const IconComponent = navItem.icon

  return (
    <li className={classNames(
      'group list-none h-full',
      { 'w-full': vertical },
      props?.container?.className,
    )}>
      <Component
        disabled={navItem?.disabled}
        className={classNames(
          className,
          navItem?.className || '',
          'relative h-full cursor-pointer',
          'flex whitespace-nowrap space-x-2 items-center',
          'hover:no-underline',
          'transition-all',
          {
            'py-4 px-3': ! noPadding,
          },
          {
            'w-full uppercase': vertical,
          },
          (
            (theme || navItem?.disabled) &&
              {
                'bg-primary text-white': optionsOpened,
                'text-primary': !optionsOpened && !navItem?.disabled && navItem?.active,
                'text-disabled group-hover:text-primary cursor-pointer': !optionsOpened && !navItem?.disabled && !navItem?.active,
                'text-disabled-light focus:text-disabled-light cursor-default': navItem?.disabled,
              }
          ),
        )}
        {...options}
        onClick={e => {
          if (navItem.options) {
            e.preventDefault()
            if ( ! navItem.disabled) {
              setOptionsOpened(!optionsOpened)
            }
            return
          }
          if (navItem.disabled) {
            e.preventDefault()
            return
          }

          if (navItem?.onClick) {
            navItem.onClick(e)
          }
        }}
        to={navItem?.href}
        href={navItem?.href}
        {...navItem?.inputProps}
      >
        {
          (
            IconComponent &&
            ! vertical
          ) &&
          <IconComponent className='fill-current text-current' />
        }
        {
          (
            navItem.image &&
            ! vertical
          ) &&
          <img src={navItem.image} alt={navItem.label} />
        }
        {
          navItem.label &&
          <span>{ navItem.label }</span>
        }
        {
          navItem.options && (
            <>
              <CaretDown
                className={classNames(
                  'transform transition-all',
                  { 'rotate-180': optionsOpened },
                )}
              />
              <SubMenu
                position={navItem.optionPosition}
                options={navItem.options}
                opened={optionsOpened}
                el={ref.current}
              />
            </>
          )
        }
        </Component>
    </li>
  )
}
